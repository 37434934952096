<template>
  <section>


  </section>
</template>

<script>
  export default {
    name: "numbers"
  }
</script>

<style scoped>
   section {
    color: #131a22;
  }
</style>