<template>
<section class="section bg-white">
  <div class="container text-center">
    <header class="section-header">
            <h2><em>&ldquo;Simply The Best Platform for Amazon Affiliates&rdquo;</em></h2>
            <hr>
            <p class="lead">This is the first feedback from the majority of Trackzon customers, it brings so much value to affliates, there is no reason to not using it. </p>
            <br>
          </header>


      <div class="row">

        <div class="logos">
          <ul class="list-unstyled">
            <li><img src="@/assets/images/logos/adobe.png"/></li>
            <li><img src="@/assets/images/logos/zendesk.png"/></li>
            <li><img src="@/assets/images/logos/ebay.png"/></li>
            <li><img src="@/assets/images/logos/evernote.png"/></li>
            <li><img src="@/assets/images/logos/airbnb.png"/></li>
            <li><img src="@/assets/images/logos/zappos.png"/></li>
          </ul>
        </div>
      </div>
  </div>

</section>
</template>

<script>
export default {
  name: "customer"
}
</script>

<style scoped>


.section .info .icon {
  background: transparent;
}

.section .icon i {
  color: #aaaaaa;
}

.section h3 {
  margin-top: 10px;
}

.logos {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.logos ul {
  display: block;
  width: 100%;
  margin: 0px auto;
  /* padding-top: 48px; */
}

.logos li {
  display: inline-block;
  margin: 0 17px;
}

span.number {
  font-size: 3rem;
  font-weight: 500;
  margin-top: 1rem;
  margin-bottom: 1.5rem; }
</style>
